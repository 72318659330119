export const state = {
    storeAdults: 2,
    storeChildren: 0,
    storeChildrenAges: [],
    storePets: false,
    storeStartDate: null,
    storeEndDate: null,
    itsInquiryForm: false,
    storeDestination: {
        id: null,
        name: null,
        slug: null,
        type: null},
}

export const mutations = {
    SET_ADULTS(state, value) {
        state.storeAdults = value;
        // console.log('Store Adults: ' + state.storeAdults);
    },
    SET_CHILDREN(state, value) {
        state.storeChildren = value;
        // console.log('Store Children: ' + state.storeChildren);
    },
    SET_CHILDREN_AGES(state, value) {
        if(Array.isArray(value)) {
            state.storeChildrenAges = value;
        } else {
            state.storeChildrenAges = [];
            let valueSplit = value.split(',');
            for (let i = 0; i < value.split(',').length; i++) {
                state.storeChildrenAges.push(valueSplit[i]);
            }
        }
        // console.log('Store Children Ages set: ' + state.storeChildrenAges);
    },
    UPDATE_CHILDREN_AGES(state, value) {
        let tempValue= state.storeChildrenAges;
        state.storeChildrenAges = [];
        state.storeChildrenAges = tempValue;
        state.storeChildrenAges[value.index] = value.alter;
        // console.log('Store Children Ages updated: ' + state.storeChildrenAges);
    },
    CHECK_CHILDREN_AGES(state, value) {
        let countChildren = value;
        let countChildrenAges = state.storeChildrenAges.length;
        if (countChildren < countChildrenAges) {
            let diff = countChildrenAges - countChildren;
            for (let i = 0; i < diff; i++) {
                state.storeChildrenAges.pop();
            }
        }
        // console.log('Store Check Children Ages: ' + state.storeChildren + ' / ' + state.storeChildrenAges);
    },
    SET_PETS(state, value) {
        state.storePets = value;
        // console.log('Store Pets: ' + state.storePets);
    },
    SET_START_DATE(state, value) {
        state.storeStartDate = value;
        // console.log('Store Startdate: ' + state.storeStartDate);
    },
    SET_END_DATE(state, value) {
        state.storeEndDate = value;
        // console.log('Store Enddate: ' + state.storeEndDate);
    },
    SET_INQUIRY_FORM(state, value) {
        state.itsInquiryForm = value;
        // console.log('Store Inquiry Form: ' + state.itsInquiryForm);
    },
    SET_DESTINATION(state, value) {
        state.storeDestination.id = value.id || null;
        state.storeDestination.name = value.name || null;
        state.storeDestination.slug = value.slug || null;
        state.storeDestination.type = value.type || null;
        // console.log('Store Destination: ', state.storeDestination);
    },
    SET_DESTINATION_NAME(state, value) {
        state.storeDestination.name = value;
        // console.log('Store Destination after Name: ', state.storeDestination);
    },
    DELETE_DESTINATION(state, value) {
        state.storeDestination = {
            id: null,
            name: null,
            slug: null,
            type: null
        }
        // console.log('Store Destination: ', state.storeDestination);
    }
}

export const actions = {
    setAdults(context, value) {
        context.commit('SET_ADULTS', value);
    },
    setChildren(context, value) {
        context.commit('SET_CHILDREN', value);
        context.commit('CHECK_CHILDREN_AGES', value);
    },
    setChildrenAges(context, value) {
        context.commit('SET_CHILDREN_AGES', value);
    },
    updateChildrenAges(context, value) {
        context.commit('UPDATE_CHILDREN_AGES', value);
    },
    setPets(context, value) {
        context.commit('SET_PETS', value);
    },
    setStartDate(context, value) {
        context.commit('SET_START_DATE', value);
    },
    setEndDate(context, value) {
        context.commit('SET_END_DATE', value);
    },
    setInquiryForm(context, value) {
        context.commit('SET_INQUIRY_FORM', value);
    },
    setDestination(context, value) {
        context.commit('SET_DESTINATION', value);
    },
    setDestinationName(context, value) {
        context.commit('SET_DESTINATION_NAME', value);
    },
    updateTimespan(context, value) {
        if (value.fromDate !== null) {
            context.commit('SET_START_DATE', new Date(value.fromDate));
            context.commit('SET_END_DATE', new Date(value.toDate));
        } else {
            context.commit('SET_START_DATE', value.fromDate);
            context.commit('SET_END_DATE', value.toDate);
        }
    },
    updateTraveling(context, value) {
        context.commit('SET_ADULTS', value.adults);
        context.commit('SET_CHILDREN', value.children);
        context.commit('SET_CHILDREN_AGES', value.childrenages);
        context.commit('SET_PETS', value.pets);
    },
    deleteDestination(context, value) {
        context.commit('DELETE_DESTINATION', value);
    },
}